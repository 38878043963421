import React from "react";
import { graphql } from "gatsby";
import MainLayout from "../layouts/MainLayout";
import { SEO, WorkPortfolio, ContactSales } from "../components/common";
import {
  Banner,
  Services,
  VideoSection,
  Industries,
  Engagement,
  TechSlider,
} from "../components/home-sections";
import erp from "../images/erp.svg";
import erpw from "../images/erp-white.svg";
import real from "../images/ppc/real.svg";
import retail from "../images/ppc/retail.svg";
import trade from "../images/ppc/trade.svg";

const HomePage = ({ data }) => {
  // const { seo } = data?.wpPage;
  const seo = {
    title: "Tv2u",
    metaDesc:
      "Global Software Development Outsourcing Company. Hire Tv2u offshore developers team and tech experts for web and product development.",
  };

  const workPortfolioData = [
    {
      name: "Homepie",
      description:
        "A free marketplace for homebuyers & sellers. Instantly list your properties, chat with buyers/sellers, close deals, and more.",
      slug: "homepie",
      image: {
        mediaItemUrl: "https://static.invozone.com/uploads/2021/04/Homepie.png",
      },
    },
    {
      name: "Anakeed Mart",
      description:
        "An online grocery shopping platform based in Saudi Arabia for providing high-quality, organic products such as fruits and vegetables straight to your doorstep.",
      slug: "anakeed-mart",
      image: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/04/Anakeed-Mart.png",
      },
    },
    {
      name: "Column",
      description:
        "A collaborative public notice platform helping journalists, governments, and legal entities to distribute public interest information.",
      slug: "column",
      image: {
        mediaItemUrl: "https://static.invozone.com/uploads/2021/06/Column.png",
      },
    },
    {
      name: "Tunevu",
      description:
        "Tunevu is an online podcasting platform providing an engaging interactive experience.",
      slug: "tunevu",
      image: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/06/Tunevu-2.png",
      },
    },
    {
      name: "Stitch.Health",
      description:
        "A care coordination platform for healthcare teams. Send messages, share ﬁles and stay in sync across multiple devices.",
      slug: "stitch-health",
      image: {
        mediaItemUrl: "https://static.invozone.com/uploads/2021/06/Stitch.png",
      },
    },
    {
      name: "Stakester",
      description:
        "Challenge your mates on this online gaming platform and put your talents to the test! Play games like Overwatch, FIFA 20, League of Legends, and Rocket League for serious merch & money.",
      slug: "stakester",
      image: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/06/Stakester.png",
      },
    },
    {
      name: "Shield Republic",
      description:
        "eCommerce platform for an American patriot brand. Find thousands of the most creative and fresh apparel & merchandise items that embody American pride.",
      slug: "shield-republic",
      image: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/06/Shieldrepublic.png",
      },
    },
    {
      name: "Meridio",
      description:
        "A blockchain-based real estate platform that converts individual properties into digital shares, seamlessly connecting diverse investors and asset owners to invest and trade.",
      slug: "meridio",
      image: {
        mediaItemUrl: "https://static.invozone.com/uploads/2021/06/Meridio.png",
      },
    },
    {
      name: "GlobalReader",
      description:
        "A comprehensive production data exchange software solution that gives you real-time information on your production capacity.",
      slug: "globalreader",
      image: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/06/Global-Reader.png",
      },
    },
    {
      name: "Curriki",
      description:
        "Online educational content creation platform for individuals and organizations.",
      slug: "curriki",
      image: {
        mediaItemUrl: "https://static.invozone.com/uploads/2021/06/Curriki.png",
      },
    },
  ];

  const servicesData = [
    {
      url: "/website-design-development/",
      serviceName: "Web Design & Development",
      description:
        "Scalable web product development at any stage and ongoing support after launch.",
      whiteIcon: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/10/website_white.svg",
      },
      colouredIcon: {
        mediaItemUrl: "https://static.invozone.com/uploads/2021/10/website.svg",
      },
      technologies: [
        {
          techName: "NodeJs",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/NodeJS.svg",
          },
        },
        {
          techName: "Elixer",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Elixer.svg",
          },
        },
        {
          techName: "Mongo DB",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/MongoDB.svg",
          },
        },
        {
          techName: "Magento",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Magento.svg",
          },
        },
      ],
    },
    {
      url: "https://invoblox.com/",
      serviceName: "Blockchain Development",
      description:
        "Wide range of reliable and tailored blockchain software application development services at your disposal.",
      whiteIcon: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/10/Blockchain-White.svg",
      },
      colouredIcon: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/10/Blockchain.svg",
      },
      technologies: [
        {
          techName: "Ethereum",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Ethereum.svg",
          },
        },
        {
          techName: "Hyperledger",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Hyperledger.svg",
          },
        },
        {
          techName: "Mist",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Mist.svg",
          },
        },
        {
          techName: "Simplicity",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Simplycity.svg",
          },
        },
        {
          techName: "Solidity",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Solidity.svg",
          },
        },
      ],
    },

    {
      url: "/",
      serviceName: "ERP Development",
      description:
        "Develop customized ERP systems to automate critical processes, fulfil productivity requirements and scale up your operational efficiency. ",
      whiteIcon: {
        mediaItemUrl: erpw,
      },
      colouredIcon: {
        mediaItemUrl: erp,
      },
      // technologies: [
      //   {
      //     techName: "After Effects",
      //     icon: {
      //       mediaItemUrl:
      //         "https://static.invozone.com/uploads/2021/10/After-Effects.svg",
      //     },
      //   },
      //   {
      //     techName: "Figma",
      //     icon: {
      //       mediaItemUrl:
      //         "https://static.invozone.com/uploads/2021/10/Figma.svg",
      //     },
      //   },
      //   {
      //     techName: "Invision",
      //     icon: {
      //       mediaItemUrl:
      //         "https://static.invozone.com/uploads/2021/10/Invision.svg",
      //     },
      //   },
      //   {
      //     techName: "Illustrator",
      //     icon: {
      //       mediaItemUrl:
      //         "https://static.invozone.com/uploads/2021/10/Illustrator.svg",
      //     },
      //   },
      //   {
      //     techName: "Photoshop",
      //     icon: {
      //       mediaItemUrl:
      //         "https://static.invozone.com/uploads/2021/10/Photoshop.svg",
      //     },
      //   },
      //   {
      //     techName: "Marvel",
      //     icon: {
      //       mediaItemUrl:
      //         "https://static.invozone.com/uploads/2021/10/marvel-icon.svg",
      //     },
      //   },
      // ],
    },
    {
      url: "/software-quality-assurance/",
      serviceName: "Software Testing Consulting",
      description:
        "In-depth software QA testing and consultation to develop bug-free software products and meet quality standards.",
      whiteIcon: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/10/software_testing_white.svg",
      },
      colouredIcon: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/10/software_testing.svg",
      },
      technologies: [
        {
          techName: "Jira",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Jira.svg",
          },
        },
        {
          techName: "Postman",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Postman.svg",
          },
        },
        {
          techName: "Soap ui",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/SoapUI.svg",
          },
        },
      ],
    },
    {
      url: "/mobile-app-development/",
      serviceName: "Mobile App Development",
      description:
        "Consultation on updating existing mobile applications and development of apps that look and perform great on both iOS and Android.",
      whiteIcon: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/10/mobile_white.svg",
      },
      colouredIcon: {
        mediaItemUrl: "https://static.invozone.com/uploads/2021/10/mobile.svg",
      },
      technologies: [
        {
          techName: "swift",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Swift.svg",
          },
        },
        {
          techName: "kotlin",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Kotlin.svg",
          },
        },
        {
          techName: "Object C",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Objective-C.svg",
          },
        },
      ],
    },
    {
      url: "/iot-development/",
      serviceName: "IoT App Development",
      description:
        "Proficient IoT app developers to build world-class, scalable, and future-ready IoT applications.",
      whiteIcon: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/10/iot_white.svg",
      },
      colouredIcon: {
        mediaItemUrl: "https://static.invozone.com/uploads/2021/10/iot.svg",
      },
      technologies: [
        {
          techName: "Arduino",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Arduino.svg",
          },
        },
        {
          techName: "OpenSCADA",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/OpenSCADA.svg",
          },
        },
        {
          techName: "Raspbian",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Raspbian.svg",
          },
        },
        {
          techName: "Tessel",
          icon: {
            mediaItemUrl:
              "https://static.invozone.com/uploads/2021/10/Tessel.svg",
          },
        },
      ],
    },
  ];

  const reliableSds = [
    {
      title: "Successful projects",
      value: "300+",
    },
    {
      title: "Customer satisfaction rate",
      value: "97%",
    },
    {
      title: "Developers and engineers",
      value: "500+",
    },
    {
      title: "Years of experience",
      value: "8+",
    },
  ];

  const industries = [
    {
      name: "E-Commerce",
      slug: "e-commerce",
      tesdescription:
        "Our team of expert software consultants and engineers will analyze your current situation and develop an eCommerce platform that meets all of your needs. We will work with you using the right tools to build an online store that captures more sales and helps you grow faster than ever before. ",
      tesimage: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/10/Ecommerce-1.png",
      },
    },
    {
      name: "Fintech",
      slug: "fintech",
      tesdescription:
        "Tv2u offers consulting services, development for custom software such as digital payment solutions, banking apps, accounting software consulting, etc. We help fintech businesses with all aspects of their operations including strategy development, product design & management, and technical support.",
      tesimage: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/10/fintech-1.png",
      },
    },
    {
      name: "Healthcare",
      slug: "healthcare",
      tesdescription:
        "Tv2u provides comprehensive healthcare software consulting and development services that include everything from idea generation through development completion including quality assurance testing and consultation and deployment support as part of our value proposition.",
      tesimage: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/10/healthcare-2.png",
      },
    },
    {
      name: "Education",
      slug: "education",
      tesdescription:
        "Tv2u builds learning management systems (LMS), virtual classrooms, and other educational solutions using the latest tech stacks. Our consultation and development services cover every step of the process, from project planning to technical implementation.",
      tesimage: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/10/Education-1.png",
      },
    },
    {
      name: "Food & Groceries",
      slug: "food-groceries",
      tesdescription:
        "Our website and mobile app consultants have ample experience in creating solutions that support commercial operations like billing, payments processing (online/in-store), order management systems (pickup lists). We can create customized platforms with responsive design your customer will love!",
      tesimage: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/10/online-retail-1.png",
      },
    },
    {
      name: "Travel & Tourism",
      slug: "travel-tourism",
      tesdescription:
        "Tv2u specializes in creating innovative solutions for hotels and travel agencies. We build apps, booking engines, real-time reservation systems, inventory management tools, data visualization dashboards, and more to help our clients increase bookings, boost revenue, and streamline business operations. ",
      tesimage: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/10/Travel-Tourism-1.png",
      },
    },
    {
      name: "On-Demand Services",
      slug: "on-demand-services",
      tesdescription:
        "Taking businesses into the on-demand stride, Tv2u stands out as one company that helps clients to design and develop on-demand mobile applications to achieve success. We provide you seasoned experts for on-demand app development services to not just sustain but thrive in your industry.",
      tesimage: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2021/10/On-Demand-Serviecs-1.png",
      },
    },
    {
      name: "Retail",
      slug: "/",
      tesdescription: `Tv2u assists retail industry players in winning the hearts of their customers with digital solutions. Our consultants and developers build retail solutions via disruptive technologies to help you stay ahead of the curve and be responsive to dynamic customer demands and purchasing patterns. `,
      tesimage: {
        mediaItemUrl: retail,
      },
    },
    {
      name: "Real Estate ",
      slug: "/",
      tesdescription: `Tv2u creates data-driven software solutions for real estate companies, buyers, sellers and agents to unlock digital channels. Capitalizing on our dynamic tech stack, we provide you with a feature-packed software program with features to dramatically improve your efficiency and brand equity! `,
      tesimage: {
        mediaItemUrl: real,
      },
    },
    {
      name: "Traders & Logistics ",
      slug: "/",
      tesdescription: `Tv2u addresses the challenges of transport & logistic companies. Our top-tier developers and consultants eagerly collaborate with you to devise automated warehousing & shipping solutions, predictive analytics, smart reporting, intelligent forecasting, and more to overcome the bottlenecks of your current logistic management system.`,
      tesimage: {
        mediaItemUrl: trade,
      },
    },
  ];

  return (
    <MainLayout>
      <SEO title={seo.title} description={seo.metaDesc} />
      <Banner />
      <Services data={servicesData} />
      <Industries data={industries} />
      <TechSlider />
      <WorkPortfolio bgColor="#fff" data={workPortfolioData} />
      <VideoSection />
      <Engagement />
      <ContactSales data={reliableSds} />
    </MainLayout>
  );
};

// export const query = graphql`
//   query {
//     wpPage(slug: { eq: "home" }) {
//       seo {
//         title
//         metaDesc
//       }
//     }
//   }
// `;

export default HomePage;
